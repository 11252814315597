/* eslint-disable */
import React from 'react';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// styles
import 'src/scss/real-talk.scss';
// hooks
import realTalkMedia from 'src/hooks/realTalkMedia';
import realTalkMobileMedia from 'src/hooks/realTalkMobileMedia';
import useMediaQuery from 'src/hooks/useMediaQuery';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';
// components
import SEO from 'src/components/SEO';
import RealTalkContent from 'src/components/real-talk/RealTalkContent';
import MobileRealTalkContent from 'src/components/real-talk/RealTalkContentMobile';

function RealTalkPage({ transitionStatus }) {
  let isDesktop = useMediaQuery('(min-width: 1024px)');
  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const mobileImages = [];
  const images = [];
  const mobileMedia = realTalkMobileMedia();
  const media = realTalkMedia();

  mobileMedia.allCloudinaryMedia.edges.forEach(item => mobileImages.push(item.node.secure_url));
  media.allCloudinaryMedia.edges.forEach(item => images.push(item.node.secure_url));

  return (
    <div>
      <SEO title="Real Talk | Falkon Content" />
      <div className="rt">
        {isDesktop
          ? (<RealTalkContent images={images} />)
          : (<MobileRealTalkContent images={mobileImages} />)
        }
      </div>
    </div>
  )
}

export default React.memo(RealTalkPage);