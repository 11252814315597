import React from 'react';
import { Parallax } from 'react-scroll-parallax';
// components
import SectionTrigger from 'src/components/SectionTrigger';
import { JumpmanIcon } from 'src/components/svgs';
import VideoPlayer from 'src/components/VideoPlayer';

function RealTalkContentMobile({ images }) {
  const scrollOffset = [40, -80];
  // const scrollOffset = [0, 0];
  const [
    realTalk01,
    realTalk02,
    realTalk03,
    realTalk04,
    realTalk05,
    realTalk06,
    realTalk07,
    realTalk08,
    realTalk09,
    realTalk10,
    realTalk11,
    realTalk12,
    realTalk13,
    realTalk14,
    realTalk15,
    realTalk16,
    realTalk17,
    realTalk18,
    realTalk19,
    realTalk20,
    realTalk21
  ] = images;

  const vid01 = 'https://player.vimeo.com/external/510552982.sd.mp4?s=b7635e7369a4e45786426a8c60578e1f8055543a&profile_id=164&oauth2_token_id=1365998561';
  const vid02 = 'https://player.vimeo.com/external/507733194.hd.mp4?s=adfa75e66ca6fe962fac05a4735f929b3de00a72&profile_id=175&oauth2_token_id=1365998561';

  return (
    <div>
      <SectionTrigger bgColor="#282c2e" textColor="#fff">
        <Hero images={[realTalk01]} />
      </SectionTrigger>
      <div className="rt-container">
        <Challenge
          images={[realTalk02, realTalk03, realTalk04,realTalk05,realTalk06,realTalk07,realTalk08]}
          scrollOffset={scrollOffset}
          videos={[vid01, vid02]}
        />
        <Solution images={[realTalk09,realTalk10,realTalk11]} scrollOffset={scrollOffset} />
        <Execution images={[realTalk12,realTalk13,realTalk14,realTalk15,realTalk16,realTalk17]} scrollOffset={scrollOffset} />
        <Impact images={[realTalk18,realTalk19,realTalk20,realTalk21]} scrollOffset={scrollOffset} />
      </div>
      <SectionTrigger
        bgColor="#282c2e"
        className="rt-section rt-section--1"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#ff0200"
        className="rt-section rt-section--2"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#2b2f30"
        className="rt-section rt-section--3"
        textColor="#fff"
      />
      <SectionTrigger
        bgColor="#ff0200"
        className="rt-section rt-section--4"
        textColor="#000"
      />
    </div>
  )
}

function Hero({ images }) {
  const [realTalk01] = images;
  return (
    <div className="rt-hero">
      <div className="rt-hero__container">
        <div className="rt-hero__header">
          <JumpmanIcon modifier="rt-hero__header-logo" />
          <h1 className="rt-hero__heading">Real Talk</h1>
          <p className="rt-hero__sub-heading">
            Episodic Content Series.
          </p>
        </div>
      </div>
      <img className="rt-img rt-img--1" src={realTalk01} />
    </div>
  )
}

function Challenge({ images, scrollOffset, videos }) {
  const [realTalk02, realTalk03, realTalk04,realTalk05,realTalk06,realTalk07,realTalk08] = images;
  const [vid01, vid02] = videos;

  return (
    <div className="rt-challenge">
      <VideoPlayer
        className="rt-video rt-video--1"
        src={vid01}
        poster={realTalk02}
      />
      <p className="rt-hero__copy">
        Real Talk Series Promotional Trailer:
      </p>
      <img className="rt-img rt-img--3" src={realTalk03} />
      <img className="rt-img rt-img--4" src={realTalk04} />
      <Parallax className="rt-challenge__heading parallax" y={scrollOffset}>
        <h3 className="challenge">Challenge</h3>
      </Parallax>
      <img className="rt-img rt-img--5" src={realTalk05} />
      <Parallax className="rt-challenge__copy rt-challenge__copy--1" y={scrollOffset}>
        <p>Create awareness & Education on equal opportunity, equal justice & voting literacy, while driving action to empower the Black Community to help fight racism and eliminate opportunity gaps.</p>
      </Parallax>
      <img className="rt-img rt-img--6" src={realTalk06} />
      <img className="rt-img rt-img--7" src={realTalk08} />
      <p className="rt-challenge__copy rt-challenge__copy--2">Manifesto Launch Film.</p>
      <VideoPlayer
        className="rt-video rt-video--2"
        src={vid02}
        poster={realTalk07}
      />
    </div>
  )
}

function Solution({ images, scrollOffset }) {
  const [realTalk09,realTalk10,realTalk11] = images;
  return (
    <div className="rt-solution">
      <img className="rt-img rt-img--9" src={realTalk09} />
      <Parallax className="rt-solution__heading" y={scrollOffset}>
        <h3 className="solution">
          Solution
        </h3>
      </Parallax>
      <p className="rt-solution__copy">
      A powerful episodic series that illuminates real conversations with activists, orgs and voices of the Jordan family, to educate our audience on voting literacy, voter suppression and ally-ship while standing in unity with the Black Community leading up to the 2020 Presidential Election and Beyond.
      </p>
      <img className="rt-img rt-img--10" src={realTalk10} />
      <div className="rt-solution__playlist">
        <p className="rt-solution__playlist-heading">
          Episode Playlist
        </p>
        <div className="rt-solution__playlist-item">
          <a href="https://www.youtube.com/watch?v=NZmpY5ig7dA&t=704s" target="_blank" rel="noopener noreferrer">
            <h3 className="rt-solution__playlist-index">
              1
            </h3>
            <p className="rt-solution__playlist-title">
              <span>Voting</span>
              <span>101</span>
            </p>
          </a>
        </div>
        <Parallax className="rt-solution__playlist-item" y={scrollOffset}>
          <a href="https://www.youtube.com/watch?v=T-NqYJ_D794&t=46s" target="_blank" rel="noopener noreferrer">
          <h3 className="rt-solution__playlist-index">
            2
          </h3>
          <p className="rt-solution__playlist-title">
            <span>Impact of</span>
            <span>law &</span>
            <span>policy reform</span>
          </p>
          </a>
        </Parallax>
        <div className="rt-solution__playlist-item">
          <a href=" https://www.youtube.com/watch?v=Wq9Qm-5Yxs4&t=14s" target="_blank" rel="noopener noreferrer">
            <h3 className="rt-solution__playlist-index">
              3
            </h3>
            <p className="rt-solution__playlist-title">
              <span>How to</span>
              <span>reform</span>
              <span>our</span>
              <span>education</span>
              <span>system</span>
            </p>
          </a>
        </div>
        <Parallax className="rt-solution__playlist-item" y={scrollOffset}>
          <a href="https://www.youtube.com/watch?v=bpqUlgpgXtw&t=1s" target="_blank" rel="noopener noreferrer">
            <h3 className="rt-solution__playlist-index">
              4
            </h3>
            <p className="rt-solution__playlist-title">
              <span>Civic</span>
              <span>engagement</span>
              <span>& what you</span>
              <span>can do</span>
            </p>
          </a>
        </Parallax>
        <div className="rt-solution__playlist-item">
          <h3 className="rt-solution__playlist-index">
            5
          </h3>
          <p className="rt-solution__playlist-title">
          <span>Beyond</span>
          <span>the ballot</span>
          </p>
        </div>
      </div>
      <img className="rt-img rt-img--11" src={realTalk11} />
    </div>
  )
}

function Execution({ images, scrollOffset }) {
  const [realTalk12,realTalk13,realTalk14,realTalk15,realTalk16,realTalk17] = images;
  return (
    <div className="rt-execution">
      <img className="rt-img rt-img--12" src={realTalk12} />
      <img className="rt-img rt-img--13" src={realTalk13} />
      <Parallax className="rt-execution__heading" y={scrollOffset}>
        <h3 className="execution">
          Execution
        </h3>
      </Parallax>
      <Parallax className="rt-execution__copy rt-execution__copy--1" y={scrollOffset}>
        <p className="rt-execution__copy-text">
          In the summer of 2020, as racial and political tensions rose, we felt deeply compelled to speak up and do something to inspire real-world action…and out of this awakening, Real Talk was born.
        </p>
      </Parallax>
      <img className="rt-img rt-img--14" src={realTalk14} />
      <img className="rt-img rt-img--15" src={realTalk15} />
      <Parallax className="rt-execution__copy rt-execution__copy--2" y={scrollOffset}>
        <p className="rt-execution__copy-text">
          We created a 5 part episodic series of 5-15 min episodes, featuring a real, raw and honest conversatoin, hosted by political activist and CNN correspondent Angela Rye and featuring a powerful range of community activists, experts and impassioned members of the Jordan Family of Athletes and Influencers to dig deep into a range of topics within the theme of VOTING.
        </p>
      </Parallax>
      <img className="global-box-shadow rt-img rt-img--16" src={realTalk16} />
      <img className="global-box-shadow rt-img rt-img--17" src={realTalk17} />
    </div>
  )
}

function Impact({ images, scrollOffset }) {
  const [realTalk18,realTalk19,realTalk20,realTalk21] = images;
  return (
    <div className="rt-impact">
      <Parallax className="rt-impact__heading" y={scrollOffset}>
        <h3 className="impact">
          Impact
          <span>Impact</span>
        </h3>
      </Parallax>
      <Parallax className="rt-impact__copy" y={scrollOffset}>
        <p className="rt-impact__copy-text">
          Real talk brought Black thought-leaders, activists, influencers and athletes together to unpack systemic racism, share complex perspectives, and ultimately, drive Black voters to the polls. The result was a powerful campaign that inspired civic engagement that will long outlast 2020 and strengthen Jordan’s legacy and continued empowerment of the Black community.
        </p>
      </Parallax>
      <img className="global-box-shadow rt-img rt-img--18" src={realTalk18} />
      <img className="global-box-shadow rt-img rt-img--19" src={realTalk19} />
      <img className="global-box-shadow rt-img rt-img--20" src={realTalk20} />
      <img className="global-box-shadow rt-img rt-img--21" src={realTalk21} />
    </div>
  )
}


export default RealTalkContentMobile;